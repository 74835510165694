import api from '../../../services/api';
import { FilterListFunctions, FunctionResponseProps } from '../types/function.type';

type Props = FilterListFunctions & {
	subAreaId: number | null;
};

export async function findFunctionSelectRequest({ subAreaId, sort }: Props): Promise<FunctionResponseProps[]> {
	const response = await api.get(`/function/subarea/${subAreaId}`, {
		params: {
			sort: sort ?? undefined,
		},
	});

	return response.data;
}
