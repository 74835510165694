import { useQuery } from '@tanstack/react-query';
import { findSubareaRequest } from '../requests/findSubareaRequest';
import { FilterListSubareas } from '../types/subarea.type';

type UseFindSubareas = {
	page: number;
	filter?: FilterListSubareas;
};

export function useFindSubareas({ page, filter }: UseFindSubareas) {
	const { data: listSubareas } = useQuery(
		['SUBAREA_LIST', page, filter],
		async () =>
			findSubareaRequest({
				pageNumber: page - 1,
				pageSize: 10,
				name: filter?.name,
				idArea: filter?.idArea,
			}),
		{ retry: false }
	);

	return { listSubareas };
}
