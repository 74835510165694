import { Title } from '../../../../components/Typography/Title';
import { Toolbar } from '../../../../components/Toolbar';
import { Table } from '../../../../components/Table';
import { Container } from './styles';
import {
	Button,
	Checkbox,
	CheckboxProps,
	Dropdown,
	Form,
	Icon,
	PaginationProps,
	Popup,
} from 'semantic-ui-react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FilterGroup } from '../../../../components/Form/FilterGroup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useFindMyQuizzes } from '../../hooks/useFindMyQuizzes';
import { useQuery } from '@tanstack/react-query';
import { findAreaRequest } from '../../../areas/requests/findAreaRequest';
import { toDropdownItemProps } from '../../../../utils/toDropdownItemProps';
import { findFunctionRequest } from '../../../functions/requests/findFunctionRequest';
import { FilterListSelfAssessments } from '../../types/quiz.type';
import { useAuth } from '../../../authentication/hooks/useAuth';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { NoResultsTableInformation } from '../../../../components/TableNoDataFound';
import { useTranslation } from 'react-i18next';
import { TableHealderProps } from '../../../../types/table.types';
import { findSubareaByAreaIdRequest } from '../../../subareas/requests/findSubareaByAreaIdRequest';

const tableHeader: TableHealderProps[] = [
	{ name: 'page.selfAssessments.fromArea' },
	{ name: 'page.selfAssessments.fromSubarea' },
	{ name: 'page.selfAssessments.fromFunction' },
	{ name: 'page.selfAssessments.targetArea' },
	{ name: 'page.selfAssessments.targetSubarea' },
	{ name: 'page.selfAssessments.targetFunction' },
	{ name: 'page.selfAssessments.createdAt', width: 2, textAlign: 'center' },
];

export function ListSelfAssessments() {
	const { t } = useTranslation();
	const { user } = useAuth();

	const { control, handleSubmit, reset, setValue } = useForm<FilterListSelfAssessments>();
	const [page, setPage] = useState(1);
	const [filter, setFilter] = useState<FilterListSelfAssessments>({ userId: user?.userId });
	const [showAllQuizzes, setShowAllQuizzes] = useState(false);
	const { isWindowSizeChange } = useMediaQuery(460);
	
	useEffect(() => {
		function initFilter() {
			setFilter(prev => ({...prev, userId: user?.userId}));
			setValue('userId', user?.userId);
		}

		initFilter();
	}, [user?.userId]);

	function handleToggleTable(_: any, data: CheckboxProps) {		
		setShowAllQuizzes((prev) => !prev);
		setFilter(prev => ({...prev, userId: data.checked ? undefined : user?.userId}));
	}

	const navigate = useNavigate();
	const location = useLocation();

	const { ListSelfAssessments } = useFindMyQuizzes({ page, filter });

	const watchFromAreaId = useWatch({ control, name: 'fromAreaId' });
	const watchToAreaId = useWatch({ control, name: 'toAreaId' });

	const { data: fromAreaOptions } = useQuery(
		['FROM_AREA_OPTIONS'],
		async () => {
			const response = findAreaRequest({});

			return toDropdownItemProps((await response)._embedded.areas);
		}
	);

	const { data: fromSubareaOptions } = useQuery(
		['FROM_SUBAREA_OPTIONS', watchFromAreaId],
		async () => {
			const response = findSubareaByAreaIdRequest(Number(watchFromAreaId));

			return toDropdownItemProps(await response);
		},
		{
			enabled: !!watchFromAreaId,
		}
	);

	const { data: toAreaOptions } = useQuery(['TO_AREA_OPTIONS'], async () => {
		const response = findAreaRequest({});

		return toDropdownItemProps((await response)._embedded.areas);
	});

	const { data: toSubareaOptions } = useQuery(
		['TO_SUBAREA_OPTIONS', watchToAreaId],
		async () => {
			const response = findSubareaByAreaIdRequest(Number(watchToAreaId));

			return toDropdownItemProps(await response);
		},
		{
			enabled: !!watchToAreaId,
		}
	);

	const { data: functionOptions } = useQuery(['FUNCTION_OPTIONS'], async () => {
		const response = findFunctionRequest({});

		return toDropdownItemProps((await response)._embedded.functions);
	});

	const handlePageChange = (
		_: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		data: PaginationProps
	) => {
		setPage(Number(data.activePage));
	};

	const onSubmitFilter = (data: FilterListSelfAssessments) => {
		setFilter({
			...data,
			fromAreaId: data.fromSubareaId,
			fromSubareaId: undefined,
			toAreaId: data.toSubareaId,
			toSubareaId: undefined,
		});
		setPage(1);
	};

	const handleResetFilter = () => {
		reset({
			userId: '',
			userName: '',
			createdAt: '',
			fromAreaId: '',
			fromFunctionId: '',
			toAreaId: '',
			toFunctionId: '',
		});
		setFilter({
			userId: user?.userId,
			userName: undefined,
			createdAt: undefined,
			fromAreaId: undefined,
			fromFunctionId: undefined,
			toAreaId: undefined,
			toFunctionId: undefined,
		});
		setShowAllQuizzes(false);
	};

	useEffect(() => {
		if (filter.userId === user?.userId) {
			setShowAllQuizzes(false);
			return;
		}

		setShowAllQuizzes(true);
	}, [filter.userId]);

	return (
		<Container>
			<Title>{t('title.selfAssessments')}</Title>

			<Toolbar
				handleAdd={() => navigate(`${location.pathname}/answer`)}
				elements={
					user?.isAdmin &&
					!isWindowSizeChange && (
						<Checkbox
							toggle
							label={t('page.selfAssessments.allItems')}
							checked={showAllQuizzes}
							onChange={handleToggleTable}
						/>
					)
				}
				filter={{
					content: (
						<>
							{user?.isAdmin && (
								<FilterGroup>
									{isWindowSizeChange && (
										<Checkbox
											toggle
											label={t('page.selfAssessments.allItems')}
											checked={showAllQuizzes}
											onChange={handleToggleTable}
										/>
									)}

									<Controller
										control={control}
										name="userId"
										render={({ field: { value, onChange } }) => (
											<Form.Input
												label={t('page.selfAssessments.userId')}
												value={value}
												onChange={onChange}
											/>
										)}
									/>

									<Controller
										control={control}
										name="userName"
										render={({ field: { value, onChange } }) => (
											<Form.Input
												label={t('page.selfAssessments.userName')}
												value={value}
												onChange={onChange}
											/>
										)}
									/>
								</FilterGroup>
							)}

							<FilterGroup>
								<Controller
									control={control}
									name="fromAreaId"
									render={({ field: { value, onChange } }) => (
										<Form.Dropdown
											label={t('page.selfAssessments.fromArea')}
											selection
											options={fromAreaOptions ?? []}
											value={value}
											onChange={(_, data) => onChange(data.value)}
										/>
									)}
								/>

								<Controller
									control={control}
									name="fromSubareaId"
									render={({ field: { value, onChange } }) => (
										<Form.Dropdown
											label={t('page.selfAssessments.fromSubarea')}
											selection
											options={fromSubareaOptions ?? []}
											value={value}
											onChange={(_, data) => onChange(data.value)}
											disabled={!watchFromAreaId}
										/>
									)}
								/>

								<Controller
									control={control}
									name="fromFunctionId"
									render={({ field: { value, onChange } }) => (
										<Form.Dropdown
											label={t('page.selfAssessments.fromFunction')}
											selection
											options={functionOptions ?? []}
											value={value}
											onChange={(_, data) => onChange(data.value)}
										/>
									)}
								/>
							</FilterGroup>

							<FilterGroup>
								<Controller
									control={control}
									name="toAreaId"
									render={({ field: { value, onChange } }) => (
										<Form.Dropdown
											label={t('page.selfAssessments.targetArea')}
											selection
											options={toAreaOptions ?? []}
											value={value}
											onChange={(_, data) => onChange(data.value)}
										/>
									)}
								/>

								<Controller
									control={control}
									name="toSubareaId"
									render={({ field: { value, onChange } }) => (
										<Form.Dropdown
											label={t('page.selfAssessments.targetSubarea')}
											selection
											options={toSubareaOptions ?? []}
											value={value}
											onChange={(_, data) => onChange(data.value)}
											disabled={!watchToAreaId}
										/>
									)}
								/>

								<Controller
									control={control}
									name="toFunctionId"
									render={({ field: { value, onChange } }) => (
										<Form.Dropdown
											label={t('page.selfAssessments.targetFunction')}
											selection
											options={functionOptions ?? []}
											value={value}
											onChange={(_, data) => onChange(data.value)}
										/>
									)}
								/>
							</FilterGroup>

							<FilterGroup>
								<Controller
									control={control}
									name="createdAt"
									render={({ field: { value, onChange } }) => (
										<Form.Input
											type="date"
											label={t('page.selfAssessments.createdAt')}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</FilterGroup>
						</>
					),
					onApply: handleSubmit(onSubmitFilter),
					onClear: handleResetFilter,
				}}
			></Toolbar>

			<span>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell content="ID" isIdColumn />
							{!filter?.userId && (
								<Table.HeaderCell content={t('page.selfAssessments.name')} />
							)}
							{tableHeader?.map(({ name, width, textAlign }) => (
								<Table.HeaderCell
									key={name}
									width={width}
									textAlign={textAlign}
								>
									{t(name as any)}
								</Table.HeaderCell>
							))}

							<Table.HeaderCell isActionColumn>
								<Icon name="cog" size="large" />
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{ListSelfAssessments?._embedded?.quizzes?.map(
							(
								{
									id,
									responsible,
									fromSubArea,
									fromFunction,
									toSubArea,
									toFunction,
									createdAt,
								},
								index
							) => (
								<Table.Row key={id}>
									<Table.Cell content={index + 1} />
									{!filter?.userId && <Table.Cell content={responsible} />}
									<Table.Cell content={fromSubArea.area.name} />
									<Table.Cell content={fromSubArea.name} />
									<Table.Cell content={fromFunction.name} />
									<Table.Cell content={toSubArea.area.name} />
									<Table.Cell content={toSubArea.name} />
									<Table.Cell content={toFunction.name} />
									<Table.Cell textAlign="center" content={createdAt} />
									<Table.Cell isActionColumn>
										<Dropdown
											pointing="right"
											trigger={<Icon name="ellipsis vertical" size="large" />}
											icon={null}
										>
											<Dropdown.Menu>
												<span className="actionColumn__buttonGroup">
													<Popup
														content="Report"
														trigger={
															<Button
																icon="file alternate"
																size="mini"
																onClick={() =>
																	navigate(`${location.pathname}/report/${id}`)
																}
															/>
														}
													/>
												</span>
											</Dropdown.Menu>
										</Dropdown>
									</Table.Cell>
								</Table.Row>
							)
						)}
					</Table.Body>
				</Table>

				{ListSelfAssessments?._embedded?.quizzes?.length ? (
					<Table.Pagination
						page={page}
						activePage={page}
						totalPages={ListSelfAssessments?.page?.totalPages ?? 0}
						boundaryRange={1}
						siblingRange={0}
						onPageChange={handlePageChange}
						size="mini"
						firstItem={null}
						lastItem={null}
						secondary
					/>
				) : (
					<NoResultsTableInformation
						icon="dont"
						title={t('message.noDataFound.title')}
						subTitle={t('message.noDataFound.subTitle')}
					/>
				)}
			</span>
			<Outlet />
		</Container>
	);
}
