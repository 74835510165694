import api from '../../../services/api';
import { PageableResponse } from '../../../types/PageableResponse.types';
import { ListSubareaResponse } from '../types/subarea.type';

interface IProps {
	name?: string;

	pageNumber?: number;
	pageSize?: number;
	idArea?: number | string;
}

export async function findSubareaRequest({
	name,
	pageNumber,
	pageSize,
	idArea,
}: IProps): Promise<PageableResponse<ListSubareaResponse>> {
	const response = await api.get('/sub-area', {
		params: {
			name: name || undefined,
			page: pageNumber ?? undefined,
			size: pageSize ?? undefined,
			idArea,
		},
	});

	return response.data;
}
