import { useQuery } from '@tanstack/react-query';
import { findFunctionRequest } from '../../functions/requests/findFunctionRequest';
import { useMemo } from 'react';
import { toDropdownItemProps } from '../../../utils/toDropdownItemProps';
import { findAreaRequest } from '../../areas/requests/findAreaRequest';
import { findSubareaByAreaIdRequest } from '../../subareas/requests/findSubareaByAreaIdRequest';
import { findFunctionSelectRequest } from '../../functions/requests/findFunctionBySubareaIdRequest';

type Props = {
	fromAreaId: number;
	toAreaId: number;
	toSubAreaId: number | null;
};

export function useQuizFormData({ fromAreaId, toAreaId, toSubAreaId }: Props) {
	const { data: areas } = useQuery(
		['AREA_LIST'],
		async () => (await findAreaRequest({}))._embedded.areas
	);

	const { data: subareasByFromAreaId } = useQuery(
		['SUBAREA_BY_FROMAREAID_OPTIONS', fromAreaId],
		async () => findSubareaByAreaIdRequest(fromAreaId),
		{
			enabled: !!fromAreaId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const { data: fromFunctions } = useQuery(
		['FROM_FUNCTION'],
		async () => (await findFunctionRequest({ active: true, pageNumber: 0, pageSize: 10, sort: 'name,asc' }))._embedded.functions,
		{
			retry: false,
			refetchOnWindowFocus: false
		}
	);

	const { data: subareasByToAreaId } = useQuery(
		['SUBAREA_BY_TOAREAID_OPTIONS', toAreaId],
		async () => findSubareaByAreaIdRequest(toAreaId),
		{
			enabled: !!toAreaId,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const { data: toFunctionsBySubAreaId } = useQuery(
		['TO_FUNCTION_BY_SUBAREAID_OPTIONS', toSubAreaId],
		async () => await findFunctionSelectRequest({ subAreaId: toSubAreaId, sort: 'name,asc' }),
		{
			enabled: !!toSubAreaId,
			retry: false,
			refetchOnWindowFocus: false
		}
	);

	const areaOptions = useMemo(
		() => areas && toDropdownItemProps(areas),
		[areas]
	);

	const fromSubareaOptions = useMemo(
		() => subareasByFromAreaId && toDropdownItemProps(subareasByFromAreaId),
		[subareasByFromAreaId]
	);

	const fromFunctionOptions = useMemo(
		() => fromFunctions && toDropdownItemProps(fromFunctions),
		[fromFunctions]
	);

	const toSubareaOptions = useMemo(
		() => subareasByToAreaId && toDropdownItemProps(subareasByToAreaId),
		[subareasByToAreaId]
	);

	const toFunctionOptions = useMemo(
		() => toFunctionsBySubAreaId && toDropdownItemProps(toFunctionsBySubAreaId),
		[toFunctionsBySubAreaId]
	);

	return {
		areas,
		areaOptions,
		fromSubareaOptions,
		fromFunctionOptions,
		toSubareaOptions,
		toFunctionOptions,
	};
}
