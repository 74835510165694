/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import i18n from 'i18next';

import enUS from './translations/en_US.json';
import ptBR from './translations/pt_BR.json';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export enum LanguageLocationEnum {
	EN_US = 'en-US',
	PT_BR = 'pt-BR',
}

export const resources = {
	'en-US': { translation: enUS },
	'pt-BR': { translation: ptBR },
};

const cachedLanguage = localStorage.getItem('@LANGUAGE');

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: {
			order: ['navigator'],
		},
		resources,
		fallbackLng: LanguageLocationEnum.EN_US,
		interpolation: { escapeValue: false },
		lng: cachedLanguage ?? undefined,
	});

export default i18n;
