import api from '../../../services/api';
import { PageableResponse } from '../../../types/PageableResponse.types';
import { ListAnswerKeyResponse } from '../types/template.type';

interface IProps {
	areaId?: string;
	subAreaId?: string;

	pageNumber?: number;
	pageSize?: number;
}

export async function findAnswerKeyRequest({
	areaId,
	subAreaId,
	pageNumber,
	pageSize,
}: IProps): Promise<PageableResponse<ListAnswerKeyResponse>> {
	const response = await api.get('/template', {
		params: {
			subAreaId: subAreaId || undefined,
			areaId: areaId || undefined,
			page: pageNumber ?? undefined,
			size: pageSize ?? undefined,
		},
	});

	return response.data;
}
