/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useNavigate } from 'react-router-dom';
import {
	Divider,
	DropdownProps,
	Form,
	Header,
	Modal,
	Segment,
	SemanticICONS,
} from 'semantic-ui-react';
import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { QuizFormProps } from '../../types/quiz.type';
import { useAnswerQuiz } from '../../hooks/useAnswerQuiz';
import { useQuizFormData } from '../../hooks/useQuizFormData';
import { SyntheticEvent, useEffect, useState } from 'react';
import { FormDropdown } from '../../../../components/Form/FormDropdown';
import { useSearchQuiz } from '../../hooks/useSearchQuiz';
import { useTranslation } from 'react-i18next';

export enum SearchQuizResponseMessage {
	FillInTheFieldsAbove = 'page.selfAssessments.modal.answerSelfAssessments.message.fillInTheFieldsAbove',
	DataNotFound = 'page.selfAssessments.modal.answerSelfAssessments.message.dataNotFound',
	ClickTheSearchQuizButton = 'page.selfAssessments.modal.answerSelfAssessments.message.clickTheSearchQuizButton',
	TargetFunctionWasNotFound = 'page.selfAssessments.modal.answerSelfAssessments.message.targetFunctionWasNotFound',
}

export type MessageProps = {
	icon: SemanticICONS;
	content: SearchQuizResponseMessage;
};

export function SelfAssessmentFormModal() {
	// @ts-ignore
	const { t } = useTranslation();
	const { control, handleSubmit, setValue } = useForm<QuizFormProps>();
	const navigate = useNavigate();
	const [message, setMessage] = useState<MessageProps>({
		icon: 'search',
		content: SearchQuizResponseMessage.FillInTheFieldsAbove,
	});

	const watchFromAreaId = useWatch({ control, name: 'fromAreaId' });

	const watchTargetAreaId = useWatch({ control, name: 'toAreaId' });
	const watchTargetSubareaId = useWatch({ control, name: 'toSubAreaId' });

	const watchTargetFunctionId = useWatch({ control, name: 'toFunctionId' });

	const { answerQuiz } = useAnswerQuiz();
	const {
		areaOptions,
		fromSubareaOptions,
		fromFunctionOptions,
		toSubareaOptions,
		toFunctionOptions
	} =
		useQuizFormData({
			fromAreaId: watchFromAreaId,
			toAreaId: watchTargetAreaId,
			toSubAreaId: watchTargetSubareaId
		});

	const { searchQuiz } = useSearchQuiz({
		watchTargetSubareaId,
		watchTargetFunctionId,
		setMessage,
	});

	const addBehaviorIdOnForm = () => {
		searchQuiz.data?.forEach(({ behaviors }, competenceIndex) =>
			behaviors.forEach(({ id: behaviorId }, behaviorIndex) => {
				setValue(
					`competencies.${competenceIndex}.behaviors.${behaviorIndex}.behaviorId`,
					behaviorId
				);
			})
		);
	};

	const clearQuiz = () => {
		searchQuiz.reset();

		if (watchTargetAreaId && watchTargetFunctionId && searchQuiz.isSuccess) {
			setValue('competencies', undefined);

			setMessage({
				icon: 'search',
				content: SearchQuizResponseMessage.ClickTheSearchQuizButton,
			});
		}
	};

	useEffect(() => {
		addBehaviorIdOnForm();
	}, [searchQuiz.data]);

	useEffect(() => {
		clearQuiz();
	}, [watchTargetAreaId, watchTargetFunctionId]);

	const handleAreaChange = (_event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		setValue('toAreaId', Number(data.value));
		setValue('toSubAreaId', null);
		setValue('toFunctionId', null);
	};	

	const handleSubAreaChange = (_event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		setValue('toSubAreaId', Number(data.value));
		setValue('toFunctionId', null);
	};	

	const onSubmit = (data: QuizFormProps) => answerQuiz.mutateAsync(data);

	return (
		<Modal
			open
			size="large"
			onClose={() => navigate(-1)}
			closeIcon
			closeOnDimmerClick={false}
		>
			<Modal.Header>{t('page.selfAssessments.modal.answerSelfAssessments.header')}</Modal.Header>

			<Modal.Content>
				<Form>
					<Form.Group widths="equal">
						<FormDropdown<QuizFormProps>
							control={control}
							name="fromAreaId"
							label={t('page.selfAssessments.fromArea')}
							options={areaOptions ?? []}
							required
							fluid
						/>
						<FormDropdown<QuizFormProps>
							control={control}
							name="fromSubAreaId"
							label={t('page.selfAssessments.fromSubarea')}
							options={fromSubareaOptions ?? []}
							required
							fluid
							disabled={!watchFromAreaId}
						/>
						<FormDropdown<QuizFormProps>
							control={control}
							name="fromFunctionId"
							label={t('page.selfAssessments.fromFunction')}
							options={fromFunctionOptions ?? []}
							required
							fluid
						/>
					</Form.Group>

					<Form.Group widths="equal">
						<FormDropdown<QuizFormProps>
							control={control}
							name="toAreaId"
							label={t('page.selfAssessments.targetArea')}
							options={areaOptions ?? []}
							onChange={handleAreaChange}
							required
							fluid
						/>
						<FormDropdown<QuizFormProps>
							control={control}
							name="toSubAreaId"
							label={t('page.selfAssessments.targetSubarea')}
							options={toSubareaOptions ?? []}
							onChange={handleSubAreaChange}
							required
							fluid
							disabled={!watchTargetAreaId}
						/>
						<FormDropdown<QuizFormProps>
							control={control}
							name="toFunctionId"
							label={t('page.selfAssessments.targetFunction')}
							options={toFunctionOptions ?? []}
							required
							fluid
							disabled={!watchTargetSubareaId}
						/>
					</Form.Group>

					<Form.Button
						icon="search"
						labelPosition="left"
						content={t('page.selfAssessments.modal.answerSelfAssessments.button.searchQuiz')}
						onClick={() => searchQuiz.mutateAsync()}
						loading={searchQuiz.isLoading}
						disabled={
							!(
								watchTargetAreaId &&
								watchTargetSubareaId &&
								watchTargetFunctionId
							) || searchQuiz.isLoading
						}
						required
					/>

					<Divider />

					<Form>
						{searchQuiz.data?.length ? (
							searchQuiz.data?.map(
								(
									{
										id: competenceId,
										title: competenceTitle,
										description: competenceDescription,
										behaviors,
									},
									competenceIndex
								) => {
									return (
										<Segment key={competenceId}>
											<Header
												as="h4"
												content={`${competenceTitle} - ${competenceDescription}`}
											/>

											<Form.Group grouped>
												{behaviors?.map(
													(
														{
															id: behaviorId,
															description: behaviorDescription,
														},
														behaviorIndex
													) => {
														return (
															<Controller
																key={behaviorId}
																control={control}
																name={`competencies.${competenceIndex}.behaviors.${behaviorIndex}.checked`}
																render={({ field: { value, onChange } }) => {
																	return (
																		<Form.Checkbox
																			label={behaviorDescription}
																			checked={value}
																			onChange={(_, data) =>
																				onChange(data.checked)
																			}
																		/>
																	);
																}}
															/>
														);
													}
												)}
											</Form.Group>
										</Segment>
									);
								}
							)
						) : (
							<Header
								as="h4"
								icon={message.icon}
								content={t(message.content)}
							/>
						)}
					</Form>
				</Form>
			</Modal.Content>

			<Modal.Actions>
				<ButtonGDY
					onClick={handleSubmit(onSubmit)}
					loading={answerQuiz.isLoading}
					disabled={answerQuiz.isLoading || !searchQuiz.data?.length}
				>
					{t('component.modal.button.save')}
				</ButtonGDY>
			</Modal.Actions>
		</Modal>
	);
}
