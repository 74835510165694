import { useEffect } from 'react';
import {
	Routes as Switch,
	Route,
	Navigate,
	useLocation,
} from 'react-router-dom';
import Layout from '../../components/Layout';
import Home from '../../features/home/pages/Home';
import Login from '../../features/authentication/pages/Login';
import PageNotFound from '../../pages/PageNotFound';
import { ListAreas } from '../../features/areas/pages/ListAreas';
import { AreaFormModal } from '../../features/areas/pages/AreaFormModal';
import { SubareaFormModal } from '../../features/subareas/pages/SubareaFormModal';
import { ListSubareas } from '../../features/subareas/pages/ListSubareas';
import { ListCompetencies } from '../../features/competencies/pages/ListCompetencies';
import { CompetenceFormModal } from '../../features/competencies/pages/CompetenceFormModal';
import { ListCapacityLevels } from '../../features/capacityLevels/pages/ListCapacityLevels';
import { CapacityLevelFormModal } from '../../features/capacityLevels/pages/CapacityLevelFormModal';
import { FunctionFormModal } from '../../features/functions/pages/FunctionFormModal';
import { ListFunctions } from '../../features/functions/pages/ListFunctions';
import { BehaviorFormModal } from '../../features/behaviors/pages/BehaviorFormModal';
import { ListBehaviors } from '../../features/behaviors/pages/ListBehaviors';
import { ListAnswerKeys } from '../../features/answerKeys/pages/ListAnswerKeys';
import { AnswerKeyFormModal } from '../../features/answerKeys/pages/AnswerKeyFormModal';
import { ListSelfAssessments } from '../../features/selfAssessments/pages/ListSelfAssessments';
import { SelfAssessmentFormModal } from '../../features/selfAssessments/pages/SelfAssessmentFormModal';
import { QuizReportModal } from '../../features/selfAssessments/pages/QuizReportModal';
import cookies from '../../utils/cookies';
import { useAuth } from '../../features/authentication/hooks/useAuth';

import ROUTES from '../config/routes.json';
import ReactGA from 'react-ga4';

function Routes() {
	const { user } = useAuth();
	const location = useLocation();

	useEffect(() => {
		const route = ROUTES.find((item) =>
			window.location.pathname.includes(item.url)
		);

		if (!route) {
			ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
			return;
		}

		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname,
			title: route.title,
		});
	}, [location]);

	return (
		<Switch>
			<Route
				path="/"
				element={
					<RequireAuth>
						<Layout />
					</RequireAuth>
				}
			>
				<Route index element={<Home />} />

				{user?.isAdmin && (
					<>
						<Route path="areas/" element={<ListAreas />}>
							<Route path="create" element={<AreaFormModal />} />
							<Route path="update/:areaId" element={<AreaFormModal />} />
						</Route>

						<Route path="subareas/" element={<ListSubareas />}>
							<Route path="create" element={<SubareaFormModal />} />
							<Route path="update/:subareaId" element={<SubareaFormModal />} />
						</Route>

						<Route path="competencies/" element={<ListCompetencies />}>
							<Route path="create" element={<CompetenceFormModal />} />
							<Route
								path="update/:competenceId"
								element={<CompetenceFormModal />}
							/>
						</Route>

						<Route path="capacityLevels/" element={<ListCapacityLevels />}>
							<Route path="create" element={<CapacityLevelFormModal />} />
							<Route
								path="update/:capacityLevelId"
								element={<CapacityLevelFormModal />}
							/>
						</Route>

						<Route path="functions/" element={<ListFunctions />}>
							<Route path="create" element={<FunctionFormModal />} />
							<Route
								path="update/:functionId"
								element={<FunctionFormModal />}
							/>
						</Route>

						<Route path="behaviors/" element={<ListBehaviors />}>
							<Route path="create" element={<BehaviorFormModal />} />
							<Route
								path="update/:behaviorId"
								element={<BehaviorFormModal />}
							/>
						</Route>

						<Route path="answerKeys/" element={<ListAnswerKeys />}>
							<Route path="create" element={<AnswerKeyFormModal />} />
							<Route
								path="update/:subareaId"
								element={<AnswerKeyFormModal />}
							/>
						</Route>
					</>
				)}

				<Route path="selfAssessments/" element={<ListSelfAssessments />}>
					<Route path="answer" element={<SelfAssessmentFormModal />} />
					<Route path="report/:quizId" element={<QuizReportModal />} />
				</Route>
			</Route>

			<Route path="/auth" element={<Login />} />
			<Route path="*" element={<PageNotFound />} />
		</Switch>
	);
}

function RequireAuth({ children }: { children: JSX.Element }) {
	const token = cookies.getCookie('@TOKEN');

	if (!token) {
		return <Navigate to="/auth" />;
	}

	return children;
}

export default Routes;
