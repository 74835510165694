import { useQuery } from '@tanstack/react-query';
import { findMyQuizzesRequest } from '../requests/findMyQuizzesRequest';
import { FilterListSelfAssessments } from '../types/quiz.type';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import enUS from 'date-fns/locale/en-US';
import { useTranslation } from 'react-i18next';
import { LanguageLocationEnum } from '../../../app/locales';

type UseFindQuizzes = {
	page: number;
	filter?: FilterListSelfAssessments;
};

export function useFindMyQuizzes({ page, filter }: UseFindQuizzes) {
	const { t, i18n } = useTranslation();

	const { data: ListSelfAssessments } = useQuery(
		['LIST_QUIZES', page, filter],
		async () =>
			findMyQuizzesRequest({
				pageNumber: page - 1,
				pageSize: 10,
				...filter,
			})
	);

	return {
		ListSelfAssessments: {
			...ListSelfAssessments,
			_embedded: {
				quizzes: ListSelfAssessments?._embedded?.quizzes?.map((quiz) => ({
					...quiz,
					createdAt: format(new Date(quiz.createdAt), t('format.date'), {
						locale: i18n.language === LanguageLocationEnum.PT_BR ? ptBR : enUS,
					}).toUpperCase(),
				})),
			},
		},
	};
}

// const ListSelfAssessments = useMutation({
// 	mutationFn: async ({ userId }: FilterListSelfAssessments) => {
// 		return (await findMyQuizzesRequest({
// 			pageNumber: page - 1,
// 			pageSize: 10,
// 			userId,
// 		}))._embedded.quizzes?.map((quiz) => ({
// 			...quiz,
// 			createdAt: format(new Date(quiz.createdAt), t('format.date'), {
// 				locale: i18n.language === LanguageLocationEnum.PT_BR ? ptBR : enUS,
// 			}).toUpperCase(),
// 		}));
// 	},
// 	onSuccess: () => {
// 		Alert({ message: 'Successfully created!', type: 'success' });
// 		queryClient.invalidateQueries(['TEMPLATE_LIST']);
// 	},
// 	onError: (error: AxiosError) => {
// 		Alert({ message: error.message, type: 'error' });
// 		queryClient.invalidateQueries(['TEMPLATE_LIST']);
// 	},
// });
